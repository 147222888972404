import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  ListItemButton,
  useMediaQuery,
} from "@mui/material";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MuiAppBar from "@mui/material/AppBar";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/img/PBA.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import bg from "../assets/img/bg.png";
import { useNavigate } from "react-router-dom";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { showLoader, hideLoader } from "../redux/loader/action";
import { showSnackbar } from "../redux/snackbar/action";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import authApi from "../services/auth";
import { setAuth } from "../redux/common/action";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useThemeContext } from '../components/theme/ThemeContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTheme } from "@mui/styles";
import {setEncryptedCookie, getDecryptedCookie, removeCookie} from "../utils"

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 50, // Medium width
  height: 28, // Medium height
  padding: 6, // Medium padding
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)', // Medium transform for off state
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)', // Medium transform for on state
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    width: 24, // Medium thumb width
    height: 24, // Medium thumb height
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 14, // Medium track border radius
  },
}));

const Navbar = ({ toggleSidebar, open }) => {
  
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [accountMenu, setaccountMenu] = useState(null);
  const navigate = useNavigate();
  // const classes = useStyles();
  const dispatch = useDispatch();
  const Theme = useTheme()
  const { theme, toggleTheme } = useThemeContext();

  const scheduleTokenRefresh = () => {
    const refreshAccessToken = async () => {
      try {
        const res = await authApi.refreshToken();
      } catch (err) {
        console.error("Failed to refresh access token:", err);
      }
    };
    setTimeout(() => {
      refreshAccessToken();
      setInterval(() => {
        refreshAccessToken();
      }, 14 * 60 * 1000);
    }, 14 * 60 * 1000);
  };

  useEffect(() => {
    scheduleTokenRefresh();
  }, []);

  const handleLogout = () => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.message || "Logged Out successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        dispatch(setAuth({ isAuthenticated: false }));
        removeCookie("at");
        removeCookie("rt");
        removeCookie("bi");
        removeCookie("bn");
        removeCookie("il");
        removeCookie("lug");
        removeCookie("un");
        removeCookie("ui");
        setEncryptedCookie("lo",true);
        navigate('/');
      }
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.message || "Failed to fetch data",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };

    authApi.logoutUser().then(onSuccess, onFailure);
    handleMenuClose();
  };
  const productCode = "75001";
  const [businessData, setBusinessData] = useState([]);
  const getBusiness = () => {
    const businessData = { product_code: productCode };
    dispatch(showLoader());

    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        const businesses = res.data.data;
        setBusinessData(businesses);
      }
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg,
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "error",
        })
      );
    };

    authApi.getBusinessId(businessData).then(onSuccess, onFailure);
  };

  useEffect(() => {
    getBusiness();
  }, []);

  const businessLoginChange = (businessId) => {
    dispatch(showLoader());
  
    const onSuccess = (res) => {
      dispatch(hideLoader());
    
      if (res?.data?.status === true) {
        const selectedOption =  businessData.find((item) => item.sso_business_id === businessId)
    
        if (!selectedOption) {
          return;
        }
    
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
            variant: "success",
          })
        );
    
        // Safely set cookies
        setEncryptedCookie("bn", selectedOption?.business_name || "", { expires: 7 });
        setEncryptedCookie("bi", selectedOption?.business_uuid || "", { expires: 7 });
        setEncryptedCookie("lug", selectedOption?.input_language || "", { expires: 7 });
        setEncryptedCookie("il", selectedOption?.is_lock || false, { expires: 7 });
        setEncryptedCookie("un", selectedOption?.user_name || "", { expires: 7 });
        setEncryptedCookie("ui", selectedOption?.user_uuid || "", { expires: 7 });
        navigate("/admin/dashboard");
      } else {
        console.error("API returned status false:", res?.data?.msg);
      }
    };
  
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg,
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "error",
        })
      );
    };
  
    authApi.businessLogin(businessId).then(onSuccess, onFailure);
  };

  const handleSetting = () => {
    navigate('/admin/setting');
    handleMenuClose();
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  const isMenuOpen = Boolean(anchorMenu);
  const isAccountMenuOpen = Boolean(accountMenu);

  const handleProfileMenuOpen = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
    setaccountMenu(null);
  };

  const handleAccountClick = (event) => {
    setaccountMenu(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      sx={{ mt: 4 }}
      anchorEl={anchorMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSetting}>
        <SettingsSuggestIcon sx={{ color: 'primary.main' }} />
        &nbsp;&nbsp;Settings
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <LogoutOutlinedIcon sx={{ color: 'primary.main' }} />
        &nbsp;&nbsp;Logout
      </MenuItem>
      
      <MenuItem onClick={() => { toggleTheme(); }} sx={{display:isMobile?"block":"none"}}>
        <ThemeSwitch checked={theme === 'dark'} /> &nbsp;&nbsp;
          {theme === 'dark' ? "Switch Light Mode" : "Switch Dark Mode"}
      </MenuItem>
    </Menu>
  );
  const AccountName = getDecryptedCookie('bn')
  const [selectedAccountName, setSelectedAccountName] = useState(AccountName);
  const renderAccountMenu = (
    <Menu
      sx={{ mt: 4 }}
      anchorEl={accountMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id="account-menu"
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isAccountMenuOpen}
      onClose={handleMenuClose}
    >
  {businessData?.map((data) => (
  <MenuItem
    key={data?.business_uuid}
    onClick={() => {
      businessLoginChange(data?.sso_business_id);
      setSelectedAccountName(data?.business_name);
      handleMenuClose()
    }}
    value={data?.business_name}
    sx={{ display: 'flex', alignItems: 'center', gap: 1 }} // Ensures icon and text are on the same line
  >
    <PermIdentityOutlinedIcon sx={{ color: 'gray' }} />
    {data?.business_name}
  </MenuItem>
))}
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed"sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: {
            xs: "70px",
            sm: "295px",
            md: "250px",
            lg: "200px",
            xl: "200px"
          },
          backgroundColor: theme === "dark" ? Theme.palette.dark.light : Theme.palette.barcolor.main,
        }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleSidebar}
            >
              {open ? (<MenuOpenIcon color="primary" />) : (<MenuIcon color="primary" />)}
            </IconButton>
            <img src={logo} width={60} alt="logo" />
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="primary"
              pl={0.5}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Pawn Broker's Automation
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
              <FormControlLabel
              onClick={toggleTheme}
        control={<ThemeSwitch  checked={theme==='dark'}  />}
        // label="MUI switch"
          sx={{display:!isMobile?"block":"none"}}/>
            <Box backgroundColor={theme==="dark"?Theme.palette.dark.light:Theme.palette.primary.light} borderRadius={2}>
              <ListItemButton onClick={handleAccountClick}>
                <SupervisorAccountOutlinedIcon  color="primary"/>
                &nbsp;&nbsp;<Typography color="primary">{selectedAccountName}</Typography>
                &nbsp;&nbsp;{isAccountMenuOpen ? <ExpandLess sx={{ color: 'primary.main' }} /> : <ExpandMore sx={{ color: 'primary.main' }} />}
              </ListItemButton>
            </Box>
            <Box>
            <IconButton
  size="large"
  edge="end"
  aria-label="account of current user"
  aria-haspopup="true"
  onClick={handleProfileMenuOpen}
  sx={{ color: theme === "dark" ? Theme.palette.dark.light:Theme.palette.primary.light }}
>
  <AccountCircle sx={{ color: theme === "dark" ? Theme.palette.dark.light:Theme.palette.primary.light }} />
</IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderAccountMenu}
      </Box>
    </>
  );
};

export default Navbar;
