import axios from "axios";
import { API_URL } from "../../constants";

export default {
  fileUpload: (data) => {
    return axios.post(API_URL.FILE_UPLOAD, data, {
      headers: {
        "Content-Type": "application/form-data",
      },
      withCredentials: true,
    });
  },
  exportData: (data) => {
    return axios.post(API_URL.FILE_UPLOAD, data, {
      headers: {
        "Content-Type": "application/form-data",
      },
      withCredentials: true,
    });
  },
  getFile: (tentId, fileId) => {
    return axios.get(`${API_URL.GET_FILE}/${tentId}/${fileId}`, {
      headers: {
        "Content-Type": "application/form-data",
      },
      withCredentials: true,
    });
  },
  countryCode: () => {
    return axios.get(API_URL.COUNTRY_CODE, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "allow",
      },
      withCredentials: true,
      params: {
        skip: 0,
        limit: 250,
      },
    });
  },
  checkConfig: (id) => {
    return axios.get(`${API_URL.CHECK_CONFIG}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "allow",
      },
      withCredentials: true,
    });
  },
  dropDown: (tentId, lookupType, searchValue, language) => {
    return axios.get(`${API_URL.SELECT_DROPDOWN}/${tentId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "allow",
      },
      withCredentials: true,
      params: {
        mast_lookup_type: lookupType,
        lookup_language: language,
        search: searchValue,
        skip: 0,
        limit: 100,
      },
    });
  },
  createdropDown: (data) => {
    return axios.post(API_URL.CREATE_DROPDOWN, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      withCredentials: true,
    });
  },

  itemDropDown: (tentId, lookupType, language) => {
    return axios.get(`${API_URL.SELECT_DROPDOWN}/${tentId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "allow",
      },
      withCredentials: true,
      params: {
        mast_lookup_type: lookupType,
        lookup_language: language,
        skip: 0,
        limit: 200,
      },
    });
  },
  getAppraiser: (page, pageSize, materialType) => {
    return axios.get(`${API_URL.GET_APPRAISER_CHARGES}/${materialType}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      params: {
        skip: page || 0,
        limit: pageSize || 10,
      },
    });
  },
};
