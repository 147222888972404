import React from 'react';
import {Outlet} from 'react-router-dom';

function AuthLayout() {
  return (
    <main >
      <section>
        <div >
            <Outlet />
        </div>
      </section>
    </main>
  );
}

export default AuthLayout;
