import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const secretKey = "your-secret-key";

export const setEncryptedCookie = (name, value, options) => {
  // Log the value before encryption
  console.log("Setting cookie for", name, "with value:", value);

  if (value === undefined || value === null || value === "") {
    console.error(
      "Cannot encrypt and set cookie: Value is invalid (undefined, null, or empty)."
    );
    return;
  }

  try {
    // Ensure the value is a valid string (encryption requires a string or number)
    const encryptedValue = CryptoJS.AES.encrypt(
      String(value),
      secretKey
    ).toString();

    // Log the encrypted value
    console.log("Encrypted value:", encryptedValue);

    Cookies.set(name, encryptedValue, options);
  } catch (error) {
    console.error("Error setting encrypted cookie:", error);
  }
};

export const getDecryptedCookie = (name) => {
  try {
    const encryptedValue = Cookies.get(name);
    if (!encryptedValue) return null;

    // Decrypt the value
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

    // If decryption failed (empty string), return null
    if (!decryptedValue) {
      console.error("Decryption failed for cookie:", name);
      return null;
    }

    return decryptedValue;
  } catch (error) {
    console.error("Error getting decrypted cookie:", error);
    return null;
  }
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};