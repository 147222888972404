import React from 'react';
import Index from './routes/index';
import { ThemeProvider  } from './components/theme/ThemeContext';
import Snackbar from './components/snackbar/index';
import { Provider } from 'react-redux';
import store from './redux/store/index'; 
import Loader from './components/loader/index';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider >
        <Index />
        <Snackbar />
        <Loader />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
