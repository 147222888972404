import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import CardMembershipIcon from '@mui/icons-material/BarChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Cashbook from '@mui/icons-material/CardMembership';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

const menuItems = [
  { text: "Dashboard", icon: <DashboardOutlinedIcon  />, path: "/admin/dashboard" },
  { text: "Customer", icon: <GroupOutlinedIcon  />, path: "/admin/customer" },
  { 
    text: "Loan", 
    icon: <LocalAtmOutlinedIcon  />, 
    path: "", 
    submenu: [
      { text: "Open", icon: <ImportContactsIcon/>, path: "/admin/loan" },
      { text: "Close", icon: <ContactsOutlinedIcon/>, path: "/admin/loanclose" },
      { text: "View", icon: <RemoveRedEyeIcon/>, path: "/admin/loanview" },
    ] 
  },
  { 
    text: "Accounts", 
    icon: <AccountBalanceOutlinedIcon  />, 
    path: "", 
    submenu: [
      { text: "Daybook", icon: <EditCalendarIcon/>, path: "/admin/daybook" },
      { text: "Audit Cashbook", icon: <Cashbook/>, path: "/admin/auditcashbook" },
      { text: "Ledger Report", icon: <CardMembershipIcon/>, path: "/admin/ledgerreport" },
      { text: "Yearly Report", icon: <QueryStatsIcon/>, path: "/admin/yearlyreport" },
    ] 
  },
  { 
    text: "Settings", 
    icon: <SettingsOutlinedIcon  />, 
    path: "", 
    submenu: [
      { text: "Configuration", icon: <TuneIcon />, path: "/admin/configuration" },
    ] 
  },
  { text: "Notice", icon: <DescriptionOutlinedIcon  />, path: "/admin/notice" },
  { text: "Reports", icon: <DescriptionOutlinedIcon  />, path: "/admin/reports" },
];

export default menuItems;
