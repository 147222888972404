import React, { lazy, Suspense, startTransition } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import MainLayout from "../layout/MainLayout";
import Spinner from "../includes/Loader";
import { useSelector } from "react-redux";

const Login = lazy(() => import("../pages/auth/Login"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgotPassword.js"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword.js"));
const Dashboard = lazy(() => import("../pages/main/dashboard/Index.js"));
const Customer = lazy(() => import("../pages/main/customer/Index.js"));
const SwitchAccount = lazy(() => import("../pages/auth/SwitchAccount.js"));
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword.js"));
const Configuration = lazy(() =>
  import("../pages/main/setting/configurations/Index.js")
);
const Loan = lazy(() => import("../pages/main/loan/Loan.js"));
const Loanclose = lazy(() => import("../pages/main/loan/Loanclose.js"));
const Colorpicker = lazy(() => import("../includes/Colorpicker.js"));
const Translator = lazy(() => import("../pages/main/Translator.js"));
const Setting = lazy(() => import("../pages/main/settingBar"));
const Print = lazy(() => import("../pages/main/loan/Print.js"));
const LoanView = lazy(() => import("../pages/main/loan/LoanView.js"));
const Reports = lazy(() => import("../pages/main/reports"));
const Daybook = lazy(() => import("../pages/main/accounts/daybook"));
const Auditbook = lazy(() => import("../pages/main/accounts/auditbook"));
const Ledgerreport = lazy(() => import("../pages/main/accounts/ledgerReport"));
const Yearlyreport = lazy(() => import("../pages/main/accounts/yearlyReport"));

export default function AppRoutes() {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route
            index
            path="/"
            element={
              <Suspense fallback={<Spinner />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="forgetpassword"
            element={
              <Suspense fallback={<Spinner />}>
                <ForgetPassword />
              </Suspense>
            }
          />
          <Route
            path="resetpassword"
            element={
              <Suspense fallback={<Spinner />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="switchaccount"
            element={
              <Suspense fallback={<Spinner />}>
                <SwitchAccount />
              </Suspense>
            }
          />
          <Route
            path="loanprint/:loanUid?"
            element={
              <Suspense fallback={<Spinner />}>
                <Print />
              </Suspense>
            }
          />
        </Route>
        <Route path="/admin" element={<MainLayout />}>
          <Route
            path="dashboard"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Dashboard} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="customer"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Customer} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="changepassword"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={ChangePassword} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="configuration"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Configuration} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="reports"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Reports} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="loan/:loanUid?/:mode?/:report?"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Loan} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          
          <Route
            path="loanclose/:loanUid?/:mode?"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Loanclose} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="daybook"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Daybook} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="auditcashbook"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Auditbook} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="ledgerreport"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Ledgerreport} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="yearlyreport"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Yearlyreport} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="colorpicker"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Colorpicker} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="translator"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Translator} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="setting"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={Setting} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="loanview"
            element={
              isAuthenticated ? (
                <SuspendedRoute component={LoanView} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <div
              style={{
                height: "95vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                color: "#a51e22",
                textAlign: "center",
              }}
            >
              <h1>404 Not Found</h1>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

function SuspendedRoute({ component: Component }) {
  return (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );
}
