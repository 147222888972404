// ThemeContext.js
import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { lightTheme, darkTheme } from '../../includes/theme';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const storedTheme = JSON.parse(localStorage.getItem('appTheme')) || 'light';
  const storedColors = JSON.parse(localStorage.getItem('appColors')) || {
    fontColor: '#000000',
    backgroundColor: '#ffffff',
    primaryMain: '#a71307',
    primaryLight: '#fceaee',
    barColor: '#ffffff',
    paper: '#ffffff',
  };

  const [theme, setTheme] = useState(storedTheme);
  const [colors, setColors] = useState(storedColors);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      resetColors(newTheme); // Update colors when theme is toggled
      return newTheme;
    });
  };

  const handleColorChange = (colorType, color) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorType]: color.hex,
    }));
  };

  const resetColors = (themeType = theme) => {
    const defaultColors = themeType === 'light' ? {
      fontColor: '#000000',
      backgroundColor: '#ffffff',
      primaryMain: '#a71307',
      primaryLight: '#fceaee',
      barColor: '#ffffff',
      paper: '#ffffff',
    } : {
      fontColor: '#ffffff',
      backgroundColor: '#1f2020',
      primaryMain: '#ad281e',
      primaryLight: '#2a2b2b',
      barColor: '#2a2b2b',
      paper: '#1f2020',
    };
    setColors(defaultColors);
  };
  const applyColors = (newColors) => {
    setColors(newColors); // Apply the new colors to the state
  };

  useEffect(() => {
    localStorage.setItem('appTheme', JSON.stringify(theme));
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('appColors', JSON.stringify(colors));
  }, [colors]);

  const currentTheme = useMemo(() => {
    const baseTheme = theme === 'light' ? lightTheme : darkTheme;
    return createTheme({
      ...baseTheme,
      palette: {
        ...baseTheme.palette,
        text: {
          primary: colors.fontColor,
        },
        background: {
          default: colors.backgroundColor,
          paper: colors.paper,
        },
        primary: {
          main: colors.primaryMain,
          light: colors.primaryLight,
        },
        barcolor: {
          main: colors.barColor, 
        },
      },
    });
  }, [theme, colors]);

  const value = useMemo(() => ({
    theme,
    toggleTheme,
    handleColorChange,
    resetColors,
    applyColors,
    colors,
  }), [theme, colors]);

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={currentTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
