import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import LoaderLottieFile from "./loader.json";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
};

export default function Loader() {
  const [open, setOpen] = React.useState(true);
  const loader = useSelector((state) => state?.loader);
  const { showLoader, loaderTxt } = loader;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    background:"transparent",
    speed:"1",
    animationData: LoaderLottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Modal open={showLoader}>
        <Box sx={style}>
          <Lottie options={defaultOptions} height={250} width={250} />
        </Box>
      </Modal>
    </div>
  );
}

