import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Box,
  Modal,
  Typography
} from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";
import { setAuth } from "../redux/common/action";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../redux/loader/action";
import {
  setEncryptedCookie,
  getDecryptedCookie,
  removeCookie,
} from "../utils";
import { useNavigate } from "react-router-dom";
import commonApi from "../services/common";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [checkConfiguration, setCheckConfiguration] = useState();
  const [openModal, setOpenModal] = useState(false); 
  const [modalMessage, setModalMessage] = useState(""); 
  const [redirectUrl, setRedirectUrl] = useState(""); 
  const business_uuid = getDecryptedCookie("bi");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null); 
  const [showModal, setShowModal] = useState(false);
  const [isInSafari, setIsInSafari] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1025);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsSettingsOpen(!isSettingsOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth < 1025 && // Only close the sidebar on smaller screens
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) && // Check if the click is outside the sidebar
        isSidebarOpen
      ) {
        setIsSidebarOpen(false); // Close the sidebar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  const configCheck = useCallback(() => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status) {
        setCheckConfiguration(res?.data?.data);
        if (!res?.data?.data?.license_exist) {
          setModalMessage(
            "License is not configured yet. You will navigate to the configuration page."
          );
          setRedirectUrl(
            "https://qc-75000biztagits01.tagcs.net/manageproducts/productconfig"
          );
          setOpenModal(true); // Show modal if license_exist is false
        } else if (!res?.data?.data?.appraiser_charges_exist) {
          setModalMessage(
            "Appraiser is not configured yet. You will navigate to the configuration page."
          );
          // setRedirectUrl("https://qc-75001pba01.tagcs.net/admin/configuration");
          setOpenModal(true); // Show modal if appraiser_charges_exist is false
        }
      }
    };
    
    const onFailure = (err) => {
      dispatch(hideLoader());
      console.log(err?.response?.data);
    };
    commonApi?.checkConfig(business_uuid).then(onSuccess, onFailure);
  }, [business_uuid, dispatch]);

  useEffect(() => {
    configCheck();
  }, [configCheck]);

  const handleOkClick = () => {
    if (!checkConfiguration.license_exist) {
    window.open(redirectUrl, "_blank"); 
    }
    else if(!checkConfiguration?.appraiser_charges_exist) {
      navigate('/admin/configuration')
      setOpenModal(false); 
    }
  };

  const sidebarWidth = isSidebarOpen ? 240 : 57;

  const hasDismissedPrompt =
    localStorage.getItem("hasDismissedShortcut") === "true";

  useEffect(() => {
    // Check if the app is in Safari or another browser
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsInSafari(isSafari); // Set the state accordingly

    if (hasDismissedPrompt) return;

    // Check for iOS device and standalone mode
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isInStandaloneMode = window.navigator.standalone;

    if (isIOS && !isInStandaloneMode) {
      setShowModal(true);
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowModal(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [hasDismissedPrompt]);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
        setShowModal(false);
      });
    }
  };

  const handleCloseModal = () => {
    localStorage.setItem("hasDismissedShortcut", "true");
    setDeferredPrompt(null);
    setShowModal(false);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} open={isSidebarOpen} />
      <Grid container sx={{ display: "flex", pt: 10, overflowX: "hidden" }}>
        <Grid
          item
          ref={sidebarRef}
          sx={{ width: sidebarWidth, transition: "width 0.3s ease" }}
        >
          <Sidebar
            open={isSidebarOpen}
            setOpen={setIsSidebarOpen}
            settingOpen={isSettingsOpen}
            setSettingOpen={setIsSettingsOpen}
          />
        </Grid>
        <Grid
          item
          sx={{ flex: 1, overflow: "hidden", transition: "width 0.3s ease" }}
        >
          <Outlet />
          <Footer isSidebarOpen={isSidebarOpen} />
        </Grid>
      </Grid>
      <Dialog open={showModal}>
        <DialogTitle>Download Application</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {isInSafari && window.navigator.standalone
              ? "Do you want to download this application?"
              : /iPhone|iPad|iPod/i.test(navigator.userAgent) &&
                !window.navigator.standalone
              ? "To add this app to your home screen, tap the 'Share' button at the bottom of Safari, then select 'Add to Home Screen'."
              : "Do you want to download this application?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {window.navigator.standalone ? (
            <>
              <Button onClick={handleAddToHomeScreen} color="primary">
                Yes
              </Button>
              <Button onClick={handleCloseModal} color="primary">
                No
              </Button>
            </>
          ) : /iPhone|iPad|iPod/i.test(navigator.userAgent) &&
            !window.navigator.standalone &&
            isInSafari ? (
            <Button onClick={handleCloseModal} color="primary">
              Do Later
            </Button>
          ) : (
            <>
              <Button onClick={handleAddToHomeScreen} color="primary">
                Yes
              </Button>
              <Button onClick={handleCloseModal} color="primary">
                No
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 3,
            boxShadow: 24,
            width: "300px",
            borderRadius: "5px",
            textAlign: "center",
            outline: "none",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleOkClick}>
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default App;
