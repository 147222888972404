import React from "react";
import Link from "@mui/material/Link";
import {
  BottomNavigation,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useThemeContext } from '../components/theme/ThemeContext';
import { lightTheme,darkTheme } from "./theme";

const useStyles = makeStyles(() => ({
  align: {
    position: "fixed",
    bottom: 0,
    padding: '2px',
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
    zIndex: 999
  },
}));

const Footer = ({ isSidebarOpen }) => {
  const classes = useStyles();
  const { theme } = useThemeContext(); // Access the current theme object from context

  const footerBackgroundColor = theme === 'dark' ? darkTheme.palette.background.default : lightTheme.palette.dark.light;

  return (
    <Box mt={10}>
      <BottomNavigation className={classes.align}
        sx={{ width: isSidebarOpen ? 'calc(100% - 240px)' : 'calc(100% - 57px)', transition: "width 0.3s ease", backgroundColor: footerBackgroundColor, height: '25px !important' }}
      >
        <Typography variant="body2" color="text.secondary" align="center" className={classes.footer} >
          {"Copyright © "}
          <Link
            color="inherit"
            href=""
            underline="none"
            sx={{ color: 'primary.main', fontWeight: 500 }}
          >
            <em>TAGCS</em>
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </BottomNavigation>
    </Box>
  );
};

export default Footer;
