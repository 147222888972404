import { combineReducers } from 'redux'
import prompt from './snackbar/reducer'
import loader from './loader/reducer'
import authReducer from './common/reducer'

const rootReducer = combineReducers({
  snackbar: prompt,
  loader: loader,
  authReducer: authReducer,
})

export default rootReducer
