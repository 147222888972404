import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          <dotlottie-player
            src="https://lottie.host/aced5ee2-3906-4249-810c-7b7d95b153ce/UvrysDo83K.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "250px" }}
            loop
            autoplay
          ></dotlottie-player>
        </Box>
      </Modal>
    </div>
  );
}
